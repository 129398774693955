<template>
  <div class="places-view">
    <div class="places-view__wrapper">
      <Section>
        <HeroTop :backgroundImage="require(`@/assets/images/${image}`)">
          <template slot="hero-top-breadcrumbs">
            <BreadCrumbs :params="{navigation, color: 'rgba(255, 255, 255, .5)'}"/>
          </template>
          <template slot="hero-top-title">
            <h1 class="hero-top__title grid-md-8 grid-lg-8">{{title}}</h1>
          </template>
          <template slot="hero-top-descriptions">
            <p class="hero-top__description grid-md-8 grid-lg-8">{{description}}</p>
          </template>
          <Row class="hero-top__anchors -m-h-5"
               wrap>
            <Anchor v-for="anchor in anchors.filter((item) => item
                                                           && item.places
                                                           && item.places.length > 0)"
                    v-on:click.native="$handleScrollTo(`#category-${anchor.id}`, 120)"
                    :key="anchor.id"
                    :params="{title: anchor.title}"/>
          </Row>
        </HeroTop>
      </Section>
      <transition name="fade">
        <Section class="single-section"
                 v-if="single"
                 limiter>
          <Row class="thematic-slider__title-holder"
               justify="between"
               align="center">
            <h2 class="thematic-slider__title">{{category.title}}</h2>
            <div class="thematic-slider__see-all">
              <Row align="center"
                   class="thematic-slider__see-all">
                <Icon class="thematic-slider__see-all-icon"
                      viewport="0 0 20 20"
                      :rotate="-90"
                      xlink="arrow-navigation"/>
                <span class="thematic-slider__see-all-text"
                      v-on:click="closeSingle">{{ $t('service.back') }}</span>
              </Row>
            </div>
          </Row>
          <Tags class="m-b-40">
            <Tag v-for="tag in category.place_types"
                 v-on:click.native="changeIdTypeCategory(categories, category.id, tag.id)"
                 :class="category.selected_type === tag.id ? 'tag__active' : ''"
                 :key="tag.id">{{tag.title}}
            </Tag>
          </Tags>
          <Sort v-if="!$mobile"
                :type="category.sortType"
                :view="viewType"
                v-on:change-sort-type="changeSortType"
                v-on:change-view-type="changeViewType"/>
          <transition name="fade">
            <div v-if="viewType === 'cards'">
              <Row class="-m-15"
                   wrap>
                <PlaceCard :key="item.id"
                           :params="item"
                           v-for="item in category.places"/>
              </Row>
            </div>
            <Row v-if="viewType === 'map'"
                 justify="between">
              <Column class="grid-md-6 grid-lg-6">
                <List>
                  <template slot="list-content">
                    <PlaceCardOnMap v-for="place in category.places"
                                    :key="`${place.id}-${update}`"
                                    v-on:click.native="showPlaceOnMap(place)"
                                    :params="place"/>
                  </template>
                </List>
              </Column>
              <Column class="grid-md-6 grid-lg-6 relative">
                <OnMap :location="place && place.location || $city.location"
                       :params="{sizeMap: {
                     width: $laptop ? 'calc(50vw - 22px)' : '100%',
                     height: $laptop ? '750px' : '300px'},}"
                       :places="!!place ? [place] : []"/>
              </Column>
            </Row>
          </transition>
        </Section>
        <div class="all-sections"
             v-else>
          <Section v-for="category in categories"
                   :id="`category-${category.id}`"
                   :key="`${category.id}-${update}`">
            <ThematicSlider :params="{array: category.places,
                                  component: 'PlaceCard',
                                  count}">
              <template slot="thematic-slider-title">
                <h2 class="thematic-slider__title">{{category.title}}</h2>
              </template>
              <template slot="thematic-slider-cloud-tags">
                <Tags class="m-b-40">
                  <Tag v-for="tag in category.place_types"
                       v-on:click.native="changeIdTypeCategory(categories, category.id, tag.id)"
                       :class="category.selected_type === tag.id ? 'tag__active' : ''"
                       :key="tag.id">{{tag.title}}
                  </Tag>
                </Tags>
              </template>
              <template slot="thematic-slider-button">
                <Row align="center"
                     class="thematic-slider__see-all">
                <span class="thematic-slider__see-all-text"
                      v-on:click="openSingle(category.id)">
                  {{$laptop ? $t('home.watch_all.p_1') : ''}} {{ $t('home.watch_all.p_2') }}
                </span>
                  <Icon class="thematic-slider__see-all-icon"
                        viewport="0 0 20 20"
                        :rotate="90"
                        xlink="arrow-navigation"/>
                </Row>
              </template>
            </ThematicSlider>
          </Section>
        </div>
      </transition>
      <Section limiter>
        <Share :url="`${$host}/places`"
               :title="share.title"
               :description="share.description"/>
      </Section>
      <ModifiedBillboard/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PlacesView',
  data() {
    return {
      id: null,
      viewType: 'cards',
      single: false,
      update: 0,
      place: null,
    };
  },
  mounted() {
    if (this.$route.hash) {
      this.$handleScrollTo(`${this.$route.hash}`, 120);
    }
  },
  computed: {
    meta() {
      return this?.$meta?.find((item) => item.slug === this.$city.slug);
    },
    image() {
      return this.$meta.find((item) => item.slug === this.$city.slug)?.places?.image
          || this.$meta.find((item) => item.slug === 'default')?.places?.image;
    },
    title() {
      return this.$meta.find((item) => item.slug === this.$city.slug)?.places?.title
          || this.$meta.find((item) => item.slug === 'default')?.places?.title;
    },
    description() {
      return this.$meta.find((item) => item.slug === this.$city.slug)?.places?.description
          || this.$meta.find((item) => item.slug === 'default')?.places?.title;
    },
    count() {
      let count;
      if (this.$laptop) count = 3;
      if (this.$tablet) count = 2;
      if (this.$mobile) count = 1;
      return count;
    },
    anchors() {
      return this.$placeTypeCategories;
    },
    category() {
      return this.$placeTypeCategories.find((item) => item.id === this.id);
    },
    categories() {
      return this.$placeTypeCategories;
    },
    navigation() {
      return [{
        href: '/',
        title: this.$i18n.t('menu.main'),
      }, {
        href: '/places',
        title: this.$i18n.t('menu.objects'),
      }];
    },
  },
  methods: {
    showPlaceOnMap(place) {
      this.place = null;
      this.$nextTick(() => {
        this.place = place;
      });
    },
    sortCommit(type) {
      this.$store.dispatch('SET_SORT_TYPE_IN_PLACE_TYPE_CATEGORIES', {
        categories: this.categories,
        categoryId: this.category.id,
        sortType: type,
      });
    },
    changeSortType(type) {
      this.sortCommit(type);
    },
    changeViewType(type) {
      this.viewType = type;
    },
    changeIdTypeCategory(categories, categoryId, typeId) {
      this.$store.dispatch('SET_ID_TYPE_IN_PLACE_TYPE_CATEGORIES', {
        categories,
        categoryId,
        typeId,
      })
        .then(() => {
          this.$nextTick(() => {
            this.update += 1;
          });
        });
    },
    openSingle(id) {
      this.id = id;
      this.single = true;
      this.$store.dispatch('GET_PLACES_FROM_SERVER_WITH_PARAMS', {
        clear: true,
        params: {
          count: 9,
          resolution: 'medium',
          sort: this.sortType,
          type_id: this.id,
          lang: this.$i18n.locale,
        },
      });
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    },
    closeSingle() {
      this.single = false;
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    },
  },
  watch: {
    categories(newState) {
      if (newState?.length > 0 && this.$route.hash !== '') {
        this.$nextTick(() => {
          this.$handleScrollTo(this.$route.hash, 120);
        });
      }
    },
  },
};
</script>
